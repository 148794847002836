import Axios from '@/axios';

export default {
  listTickets: async (params = {}) => {
    const response = await Axios.get(`/ticket-service/tickets`, {
      params
    });

    return response.data.data;
  },
  readTicket: async (ticketId) => {
    const response = await Axios.get(`/ticket-service/${ticketId}`);

    return response.data.data;
  }
};
