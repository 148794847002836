import Axios from '@/axios';

export default {
  closeGoldrushEvent: async (masterEvent, eventId, body) => {
    const response = await Axios.post(`goldrush-service/master-event/${masterEvent}/rng-close/${eventId}`, body, {
      timeout: 60_000
    });
    return response.data.data;
  },
  manualCloseGoldrushEvent: async (masterEvent, eventId, body) => {
    const response = await Axios.post(`goldrush-service/master-event/${masterEvent}/manual-close/${eventId}`, body);

    return response.data.data;
  },
  closeEvent: async (eventId, barrelDraw, ticketNumber) => {
    const closeUrl = barrelDraw ? 'manual-close' : 'rng-close';
    const body = barrelDraw ? { winningTicket: ticketNumber } : null;

    const response = await Axios.post(`/event-service/events/${eventId}/${closeUrl}`, body);

    return response.data.data;
  },
  createRaffle: async (body) => {
    const response = await Axios.post(`/event-service/events/`, body);

    return response.data.data;
  },
  copyRaffle: async (eventId, body) => {
    const response = await Axios.post(`/event-service/events/${eventId}/copy`, body);

    return response.data.data;
  },
  updateRaffle: async (eventId, body) => {
    const response = await Axios.patch(`/event-service/events/${eventId}`, body);

    return response.data.data;
  },
  activateEvent: async (eventId) => {
    const response = await Axios.post(`/event-service/events/${eventId}/activate`, null);

    return response.data.data;
  },
  readEvent: async (eventId) => {
    const response = await Axios.get(`/event-service/events/${eventId}`);

    return response.data.data;
  },
  listEvents: async (params = {}) => {
    const response = await Axios.get(`/event-service/events`, {
      params
    });

    return response.data.data;
  },
  listEventMembers: async (eventId) => {
    const response = await Axios.get(`/event-service/events/${eventId}/event-members`);

    return response.data.data;
  }
};
