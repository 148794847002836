<template>
  <div>
    <b-overlay spinner-small variant="transparent" blur="none" :show="submitting" class="d-inline-block">
      <b-form-checkbox v-model="printed" switch class="d-inline-block" :disabled="submitting" @change="setPrinted" />
    </b-overlay>
  </div>
</template>

<script>
import TicketService from '@/lib/ticket-service-v2';

export default {
  name: 'TicketPrintedToggle',
  props: ['value', 'ticketId'],
  data() {
    return {
      submitting: false,
      errorMessage: '',
      printed: this.value
    };
  },
  methods: {
    async setPrinted(value) {
      this.submitting = true;

      try {
        await TicketService.updateTicket(this.ticketId, { printed: value });
        this.$emit('input', value);
      } catch (error) {
        this.$emit('error', error);

        this.$nextTick(() => {
          this.printed = this.value;
        });
      }
      this.submitting = false;
    }
  }
};
</script>
