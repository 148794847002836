<template>
  <b-container class="mb-7">
    <h1>Tickets</h1>

    <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>

    <b-spinner v-if="showEventSpinner">Loading event</b-spinner>

    <b-card v-if="event" style="border: none">
      <b-card-title>{{ event.name }} {{ event.drawNum ? event.drawNum : '' }}</b-card-title>

      <p v-if="event.drawDate"><strong>Draw Date</strong>: {{ formatDateTime(parseISO(event.drawDate)) }}</p>

      <b-card-body>
        <b-list-group class="ticket-totals-group">
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            Tickets
            <b-badge variant="light" pill>{{ formatNumber(table.rows) }}</b-badge>
          </b-list-group-item>

          <b-list-group-item class="d-flex justify-content-between align-items-center">
            Printed
            <div>
              <b-button v-if="canPrintTickets" size="sm" variant="outline-danger" class="mr-0" v-b-modal.unprint-modal
                >Unprint all tickets</b-button
              >
              <!-- <b-badge variant="light" pill>{{ formatNumber(printedCount) }}</b-badge> -->
            </div>
          </b-list-group-item>

          <!-- <b-list-group-item class="d-flex justify-content-between align-items-center">
            Remaining
            <b-badge variant="light" pill>{{ formatNumber(table.rows - printedCount) }}</b-badge>
          </b-list-group-item> -->
        </b-list-group>
      </b-card-body>
    </b-card>
    <b-modal id="unprint-modal" title="Unprint Event" @ok="unprintEvent" body-class="position-static">
      <b-overlay no-wrap :show="unprinting" rounded />
      <b-alert v-if="unprintingError" variant="danger" show>{{ unprintingError }}</b-alert>
      Are you sure you wish to unset the printed status of all tickets in this event?
    </b-modal>
    <b-row class="container-search">
      <b-col sm="4">
        <b-form-input v-model="form.search" placeholder="Enter search" debounce="300"></b-form-input>
      </b-col>
      <b-col sm="4">
        <b-form-checkbox v-model="form.hidePrinted" class="mt-1" switch>Hide Printed</b-form-checkbox>
      </b-col>
    </b-row>
    <b-table
      show-empty
      id="table"
      class="ticket-table"
      striped
      sort-icon-left
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:cell(createdAt)="row">
        <span v-b-tooltip.hover.html="tipMethod(row.item)" class="dashline">
          {{ formatDateTime(parseISO(row.item.createdAt)) }}
        </span>
      </template>
      <template v-slot:cell(printed)="data">
        <TicketPrintedToggle
          v-if="canPrintTickets"
          v-model="data.item.printed"
          :ticketId="data.item.id"
          @error="ticketToggleErrored"
        />
        <div v-else>
          <b-badge variant="secondary" v-if="data.item.printed === false">Not Printed</b-badge>
          <b-badge variant="success" v-if="data.item.printed === true">Printed</b-badge>
        </div>
      </template>
    </b-table>
    <b-tfoot>
      <b-tr>
        <b-td colspan="7" class="text-right">
          Total Rows: <b> {{ table.rows }}</b>
        </b-td>
      </b-tr>
    </b-tfoot>
    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      aria-controls="table"
      pills
      align="center"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    >
    </b-pagination>
  </b-container>
</template>

<script>
import EventService from '@/lib/event-service';
import TicketService from '@/lib/ticket-service';
import TicketServiceV2 from '@/lib/ticket-service-v2';
import { parseISO } from 'date-fns/fp';
import TicketPrintedToggle from '@/components/TicketPrintedToggle.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
export default {
  name: 'Ticket',
  components: { TicketPrintedToggle },
  watch: {
    'form.search': function () {
      this.refreshTable();
    },
    'form.hidePrinted': function () {
      this.refreshTable();
    }
  },
  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    loadEvent(eventId) {
      EventService.readEvent(eventId)
        .then((data) => {
          this.showEventSpinner = false;
          this.event = data.data;
        })
        .catch((error) => {
          this.showEventSpinner = false;
          this.error = error;
        });
    },
    ticketToggleErrored(error) {
      this.error = error;
    },
    items() {
      const eventId = this.$route.query.eventId;
      const orderId = this.$route.query.orderId;
      const params = {
        eventId: eventId,
        orderId: orderId,
        search: this.form.search ? this.form.search : undefined,
        printed: this.form.hidePrinted ? false : undefined,
        page: this.table.currentPage - 1,
        pageSize: 10,
        sortBy: this.table.sortBy || undefined,
        sortDir: this.sortDir
      };
      return TicketService.listTickets(params)
        .then((result) => {
          this.table.rows = result.pagination.total;
          this.printedCount = 0;
          this.showEventSpinner = false;
          return result.data;
        })
        .catch((error) => {
          this.error = `Failed to get tickets ${error}`;
          this.showEventSpinner = false;
          return [];
        });
    },
    async unprintEvent(evt) {
      evt.preventDefault();
      this.unprinting = true;
      this.unprintingError = '';
      const eventId = this.$route.query.eventId;
      try {
        await TicketServiceV2.unprintEvent(eventId);
        this.$router.go();
      } catch (error) {
        if (error.response) {
          this.unprintingError = error.response.data.errors[0].message;
        } else {
          this.unprintingError = error;
        }
        this.unprinting = false;
      }
    },
    tipMethod(rowItem) {
      return (
        this.formatDateTime(parseISO(rowItem.createdAt), {
          format: 'date'
        }) +
        ',' +
        '<br>' +
        this.formatDateTime(parseISO(rowItem.createdAt), {
          format: 'time',
          timeZone: this.sessionUserTimeZone,
          displayTimeZone: true
        })
      );
    }
  },
  data() {
    return {
      form: {
        search: '',
        hidePrinted: false
      },
      error: null,
      showEventSpinner: true,
      printedCount: 0,
      event: null,
      table: {
        isBusy: true,
        fields: [
          {
            key: 'createdAt',
            label: 'Created',
            formatter: (val) => {
              if (!val) {
                return '';
              }
              return this.formatDateTime(parseISO(val));
            }
          },
          {
            key: 'ticketNumber',
            label: 'Ticket Number',
            formatter: (value, key, item) => {
              if (item.subTicket) {
                return `${item.ticketNumber}-${item.subTicket}`;
              } else {
                return item.ticketNumber;
              }
            }
          },
          {
            key: 'series',
            label: 'Series'
          },
          {
            key: 'printed',
            label: 'Printed'
          }
        ],
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'ticketNumber',
        sortDesc: false
      },
      unprinting: false,
      unprintingError: '',
      canPrintTickets: false
    };
  },
  mounted() {
    this.canPrintTickets = unleashFeatureEnabled(UnleashKeys.PrintTickets);
  },
  created() {
    this.event = null;
    this.showEventSpinner = true;
    const eventId = this.$route.query.eventId;
    const orderId = this.$route.query.orderId;
    if (!eventId && !orderId) {
      this.error = 'No eventId or orderId';
    } else {
      if (eventId) {
        this.loadEvent(eventId);
      }
    }
  }
};
</script>
<style scoped>
.button-container {
  padding-top: 2rem;
}
.ticket-totals-group {
  max-width: 20rem;
}
.container-search {
  padding-bottom: 1rem;
}
.dashline {
  border-bottom: 1px dashed;
}
</style>
